import React from "react";
import IframeComm from "react-iframe-comm";
import { Form, Progress } from "semantic-ui-react";
import "./onboarding.scss";
import i18next from "i18next";
import { apiURL } from "../../config";

interface IProps {
  handleStateChange: (name: string, value: any) => any;
  previousState: any;
  screenSize: string;
}

export default class WebCamDistance extends React.Component<IProps> {
  // the postMessage data you want to send to your iframe
  // it will be send after the iframe has loaded
  postMessageData = { level: 1 };
  state = {
    GoodDistance: false,
    Distance: 0.0,
    min: 0,
    max: 0,
  };
  componentDidMount() {
    let newState = { ...this.state };
    if (this.props.screenSize == "Moins de 13 pouces") {
      newState.min = 0;
      newState.max = 50;
    } else {
      let screenSizeInch = this.props.screenSize.split(" ")[0];
      switch (screenSizeInch) {
        case "13": {
          newState.min = 46;
          newState.max = 54;
          break;
        }
        case "14": {
          newState.min = 50;
          newState.max = 57;
          break;
        }
        case "15": {
          newState.min = 54;
          newState.max = 62;
          break;
        }
        case "16": {
          newState.min = 57;
          newState.max = 65;
          break;
        }
        case "17": {
          newState.min = 62;
          newState.max = 69;
          break;
        }
        case "18": {
          newState.min = 65;
          newState.max = 72;
          break;
        }
        case "19": {
          newState.min = 69;
          newState.max = 77;
          break;
        }
        case "20": {
          newState.min = 72;
          newState.max = 80;
          break;
        }
        case "21": {
          newState.min = 77;
          newState.max = 100;
          break;
        }
      }
    }
    this.setState(newState);

    if (this.props.previousState) {
      let state = { ...this.state };
      state = this.props.previousState;
      this.setState(state);
    }
  }

  private WebGazer() {
    const attributes = {
      src: `${apiURL}/HeadTracker`,
      width: "100%",
      height: "100%",
      frameBorder: 1, // show frame border just for fun...
      allow: "camera",
    };

    return (
      <div className="CameraIframe">
        <IframeComm attributes={attributes} postMessageData={this.postMessageData} handleReady={this.onReady} handleReceiveMessage={this.onReceiveMessage} />
      </div>
    );
  }
  onReceiveMessage = async (event: any) => {
    console.log("onReceiveMessage");
    let state = { ...this.state };
    if (event?.data && !event?.data.payload) state.Distance = JSON.parse(event.data).distance;
    if (this.state.Distance >= this.state.min && this.state.Distance <= this.state.max) {
      state.GoodDistance = true;
    } else {
      state.GoodDistance = false;
    }

    this.setState(state);
    await this.props.handleStateChange("WebCamDistance", state);
    await this.props.handleStateChange("GoodDistance", state.GoodDistance);
  };

  // iframe has loaded
  onReady = () => {
    console.log("onReady");
  };
  render() {
    return (
      <Form className="ergonomie-form">
        {this.WebGazer()}
        <div style={{ width: "95%", margin: "auto" }}>
          <p style={{ maxWidth: "350px", margin: "20px auto" }}>{i18next.t("tests.ergonomics.webcamDistance1")}</p>
          <div>
            {" "}
            <Progress percent={this.GetProgress()} color={this.GetColor()}></Progress>
          </div>
          <p style={{ margin: "auto" }}>{this.GetMessage()}</p>
        </div>
      </Form>
    );
  }

  private GetProgress = (): string | number | undefined => {
    if (this.state.Distance > 100) {
      return 100;
    } else return this.state.Distance;
  };
  private GetMessage = () => {
    if (this.state.Distance >= this.state.min && this.state.Distance <= this.state.max) {
      return i18next.t("tests.ergonomics.webcamDistance2");
    }
    if (this.state.Distance <= this.state.min) {
      return i18next.t("tests.ergonomics.webcamDistance3");
    }
    if (this.state.Distance > this.state.max) {
      return i18next.t("tests.ergonomics.webcamDistance4");
    }
  };
  private GetColor = (): "green" | "red" | "orange" | "yellow" | "olive" | "teal" | "blue" | "violet" | "purple" | "pink" | "brown" | "grey" | "black" | undefined => {
    if (this.state.Distance >= this.state.min && this.state.Distance <= this.state.max) {
      return "green";
    }
    if (this.state.Distance <= this.state.min) {
      return "red";
    }
    if (this.state.Distance > this.state.max) {
      return "yellow";
    }
  };
}
