import i18next from "i18next";
import { isMobile } from "react-device-detect";
import IframeComm from "react-iframe-comm";
import { Form, Radio } from "semantic-ui-react";
import "./onboarding.scss";
import { set } from "date-fns";

const ErgonomieForm = ({ ergonomyResults, setErgonomyResults }) => {
  const handleChange = async (_event, { name, value }) => {
    switch (name) {
      case "Airconditioning":
        setErgonomyResults({ ...ergonomyResults, Airconditioning: value });
        break;
      case "ScreenHeight":
        setErgonomyResults({ ...ergonomyResults, ScreenHeight: value });
        break;
      case "Desk":
        setErgonomyResults({ ...ergonomyResults, Desk: value });
        break;
      case "WebCamera":
        setErgonomyResults({ ...ergonomyResults, WebCamera: value });
        break;
      case "GoodHeight":
        setErgonomyResults({ ...ergonomyResults, GoodHeight: value });
        break;
      case "Sex":
        setErgonomyResults({ ...ergonomyResults, Sex: value });
        break;
      case "Age":
        setErgonomyResults({ ...ergonomyResults, Age: value });
        break;
      default:
        break;
    }
  };

  const DesktopOnlyFeatures = () => {
    if (!isMobile) {
      return (
        <div>
          {WebCamera()}
          {WebGazer()}
        </div>
      );
    }
  };

  // the postMessage data you want to send to your iframe
  // it will be send after the iframe has loaded
  const postMessageData = { level: 1 };

  // parent received a message from iframe
  const onReceiveMessage = (event) => {
    console.log("onReceiveMessage");
    console.log(event.data);
    if (event && event.data && Array.isArray(event.data) && event.data.includes("green")) {
      setErgonomyResults({ ...ergonomyResults, GoodHeight: "Oui" });
    } else if (event && event.data && Array.isArray(event.data) && event.data.includes("high")) {
      setErgonomyResults({ ...ergonomyResults, GoodHeight: "Non" });
    } else if (event && event.data && Array.isArray(event.data) && event.data.includes("low")) {
      setErgonomyResults({ ...ergonomyResults, GoodHeight: "Non" });
    }
  };

  // iframe has loaded
  const onReady = () => {
    console.log("onReady");
  };

  const WebGazer = () => {
    const attributes = {
      src: "/facedetect/webgazer.html",
      width: "100%",
      height: "100%",
      frameBorder: 1, // show frame border just for fun...
    };
    if (ergonomyResults.WebCamera == "Oui") {
      return (
        <div className="CameraIframe">
          <IframeComm attributes={attributes} postMessageData={postMessageData} handleReady={onReady} handleReceiveMessage={onReceiveMessage} />
        </div>
      );
    }
  };

  const Airconditioning = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question10")}</label>
        <Form.Field>
          <Radio toggle label={i18next.t("common.yes")} name="Airconditioning" value="Oui" checked={ergonomyResults.Airconditioning == "Oui"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("common.no")} name="Airconditioning" value="Non" checked={ergonomyResults.Airconditioning == "Non"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer101")}
            name="Airconditioning"
            value="De temps en temps"
            checked={ergonomyResults.Airconditioning == "De temps en temps"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const GoodHeight = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question11")}</label>

        <Form.Field>
          <Radio disabled toggle label={i18next.t("common.yes")} name="GoodHeight" value="Oui" checked={ergonomyResults.GoodHeight == "Oui"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio disabled toggle label={i18next.t("common.no")} name="GoodHeight" value="Non" checked={ergonomyResults.GoodHeight == "Non"} onChange={handleChange} />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const WebCamera = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question12")}</label>

        <Form.Field>
          <Radio toggle label={i18next.t("common.yes")} name="WebCamera" value="Oui" checked={ergonomyResults.WebCamera == "Oui"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("common.no")} name="WebCamera" value="Non" checked={ergonomyResults.WebCamera == "Non"} onChange={handleChange} />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const ScreenHeight = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question13")}</label>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer131")}
            name="ScreenHeight"
            value="A la même hauteur que vos yeux"
            checked={ergonomyResults.ScreenHeight == "A la même hauteur que vos yeux"}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer132")}
            name="ScreenHeight"
            value="Plus haut que vos yeux"
            checked={ergonomyResults.ScreenHeight == "Plus haut que vos yeux"}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer133")}
            name="ScreenHeight"
            value="Plus bas que vos yeux"
            checked={ergonomyResults.ScreenHeight == "Plus bas que vos yeux"}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer134")}
            name="ScreenHeight"
            value="Cela dépend des situations"
            checked={ergonomyResults.ScreenHeight == "Cela dépend des situations"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const Desk = () => (
    <div>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question14")}</label>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer141")}
            name="Desk"
            value="Perpendiculaire à une fenêtre"
            checked={ergonomyResults.Desk == "Perpendiculaire à une fenêtre"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer142")}
            name="Desk"
            value="Dos à une fenêtre"
            checked={ergonomyResults.Desk == "Dos à une fenêtre"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer143")}
            name="Desk"
            value="face à une fenêtre"
            checked={ergonomyResults.Desk == "face à une fenêtre"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer144")}
            name="Desk"
            value="Cela dépend des situations"
            checked={ergonomyResults.Desk == "Cela dépend des situations"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Field>
    </div>
  );

  const Sex = () => (
    <span>
      <br />
      <br />
      <h4 dangerouslySetInnerHTML={{ __html: i18next.t("tests.vitality.question1819intro") }} />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question18")}</label>

        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer18.1")} name="Sex" value="Un homme" checked={ergonomyResults.Sex == "Un homme"} onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer18.2")} name="Sex" value="Une femme" checked={ergonomyResults.Sex == "Une femme"} onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer18.3")} name="Sex" value="Autre" checked={ergonomyResults.Sex == "Autre"} onChange={handleChange} />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const Age = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question19")}</label>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer19.2")}
            name="Age"
            value="Entre 18 et 30 ans"
            checked={ergonomyResults.Age == "Entre 16 et 30 ans"}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer19.3")}
            name="Age"
            value="Entre 31 et 45 ans"
            checked={ergonomyResults.Age == "Entre 31 et 45 ans"}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer19.4")}
            name="Age"
            value="Entre 46 et 65 ans"
            checked={ergonomyResults.Age == "Entre 46 et 65 ans"}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer19.5")}
            name="Age"
            value="Plus de 66 ans"
            checked={ergonomyResults.Age == "Plus de 66 ans"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Field>
    </span>
  );

  return (
    <Form id="fatigue-form">
      <h3> </h3>
      <div>
        {Airconditioning()}
        {ScreenHeight()}
        {Desk()}
        {Sex()}
        {Age()}
      </div>
    </Form>
  );
};

export default ErgonomieForm;
