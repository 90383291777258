import React, { useState } from "react";
import { Card, Icon, Image, Loader, Segment } from "semantic-ui-react";
import i18next from "../../i18n";
import scoreDescription from "../onboarding/ScoreDescription";
import { GetCumulativeScores, GetExcercisesData } from "../stats/CumulativeChart";
import VitalityGauge from "../onboarding/VitalityGauge";

export function Stats({ state, setState }) {
  const [vitalityDescriptionVisible, setVitalityDescriptionVisible] = useState(false);

  if (!state) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  if (state.loading) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  const toggleVisibility = () => {
    setVitalityDescriptionVisible((prevVisibility) => !prevVisibility);
  };

  var options = { year: "numeric", month: "long", day: "numeric" };

  return (
    <Card style={{ width: "100%" }}>
      <Card.Content>
        <Card.Header className="head-title">{i18next.t("dashboard.stats.title")}</Card.Header>
        <div style={{ margin: "1em auto", width: "100%", color: "rgba(0,0,0,.6)" }}>
          <h3 style={{ display: "flex", alignItems: "center", color: "#2185d0" }}>
            <span style={{ textTransform: "uppercase" }}>{i18next.t("common.vitalityIndex")}</span>
            <span style={{ margin: "0 8px" }}>{100 - (state.Percentage || 100)}%</span>
            <Icon link size="tiny" inverted circular name="info" onClick={toggleVisibility} />
          </h3>

          <div
            style={{ overflow: "hidden", transition: "max-height .5s", maxHeight: vitalityDescriptionVisible ? "300px" : 0 }}
            dangerouslySetInnerHTML={{ __html: scoreDescription(100 - (state.Percentage || 100)) }}
          />
          {VitalityGauge(state)}
          {state.Fatigue?.testdate && (
            <div style={{ textAlign: "center", fontSize: "12px" }}>{new Intl.DateTimeFormat(i18next.language, options).format(new Date(state.Fatigue.testdate))}</div>
          )}
        </div>
        <div className="scoresPlot" style={{ display: "flex", flexDirection: "column", width: "100%", color: "#2185d0" }}>
          <h3>{i18next.t("dashboard.stats.scores")}</h3>
          <div className="scoresPlot">
            {GetCumulativeScores(state.userid, state.code, GetExcercisesData(state.Scores), (Scores) => setState((prevState) => ({ ...prevState, Scores })))}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
}
