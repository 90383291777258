import i18next from "i18next";
import React from "react";
import IframeComm from "react-iframe-comm";
import { Form } from "semantic-ui-react";
import "./onboarding.scss";
import path from 'path'
import { apiURL } from "../../config";
interface IProps {
  handleStateChange: (name: string, value: any) => any;
  previousState: any;
}

export default class WebCamHeight extends React.Component<IProps> {
  // the postMessage data you want to send to your iframe
  // it will be send after the iframe has loaded
  postMessageData = { level: 1 };
  state = {
    GoodHeight: false
  };
  componentDidMount() {
    if (this.props.previousState) {
      let state = { ...this.state };
      state = this.props.previousState;
      this.setState(state);
    }
  }

  handleChange = async (_event: any, { name, value }: any) => {
    let state = { ...this.state };
    switch (name) {
      case "GoodHeight": {
        state.GoodHeight = value;
        break;
      }
    }
    await this.props.handleStateChange("WebCamHeight", state);
    this.setState(state);
  };
  private WebGazer() {
    const attributes = {
      src: `${apiURL}/WebGazer`,
      width: "100%",
      height: "100%",
      frameBorder: 0, // show frame border just for fun...
      allow:"camera *"
    };

    return (
      <div className="CameraIframe">
        <IframeComm
          attributes={attributes}
          postMessageData={this.postMessageData}
          
          handleReady={this.onReady}
          handleReceiveMessage={this.onReceiveMessage}
        />
      </div>
    );
  }
  onReceiveMessage = async (event: any) => {
    let state = { ...this.state };
    if (event && event.data && Array.isArray(event.data)) {
      if (event.data.includes("green")) {
        state.GoodHeight = true;
        this.setState(state);
      } else if (event.data.includes("high")) {
        state.GoodHeight = false;
        this.setState(state);
      } else if (event.data.includes("low")) {
        state.GoodHeight = false;
        this.setState(state);
      }
      await this.props.handleStateChange("WebCamHeight", state);
    }
  };

  // iframe has loaded
  onReady = () => {
    console.log("onReady");
  };
  render() {
    return (
      <Form className="ergonomie-form">
        {this.WebGazer()}
        <div style={{ width: "95%", margin: "20px auto" }}>
          <p style={{ width: "75vh", margin: "0 auto 1em" }}>{i18next.t('tests.ergonomics.webcamPosition0')}</p>
          <p style={{ width: "75vh", margin: "auto" }}>{i18next.t('tests.ergonomics.webcamPosition1')}</p>
          <p style={{ width: "75vh", margin: "auto" }}>{i18next.t('tests.ergonomics.webcamPosition2')}</p>
          <p style={{ width: "75vh", margin: "1em auto 0" }}>
            {this.state.GoodHeight
              ? i18next.t('tests.ergonomics.webcamPosition3')
              : i18next.t('tests.ergonomics.webcamPosition4')}
          </p>
        </div>
      </Form >
    );
  }
}
